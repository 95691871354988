/* You can add global styles to this file, and also import other style files */
body {
  padding-top: 0 !important;
}
.btn:focus {
    box-shadow:none !important;
    transition: box-shadow 0.2s ease-in-out !important;
}
// For photo download drop zone
// https://github.com/georgipeltekov/ngx-file-drop/issues/60
.lc-drop-zone {
  border: 2px dashed #6c757d;
  border-radius: 5px;

  .content {
    height: 200px !important;
  }

  .processing-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .align-content-center {
    text-align: center;
    line-height: 16px;
  }

  .btn-link {
    padding: 0;
  }
}
